import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { ReactComponent as EndGame } from '../../assets/EndGame.svg';
import greyCircle from '../../assets/grey-circle.svg.hi.png';

export const TopBar = (user: any) => {
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [balanceClass, setBalanceClass] = useState('');
  const navigate = useNavigate();

  function navToAuth() {
    navigate("/login");
  }

  useEffect(() => {
    if(currentUser &&  currentUser?.availableBalance) {
      if(user?.currentUser?.availableBalance > currentUser?.availableBalance) {
        //show balance green
        setBalanceClass('balance-increase')
        setTimeout(() => {
          setBalanceClass('')
        }, 3000);

      } else if(user.currentUser?.availableBalance < currentUser?.availableBalance) {
        //show balance green
        setBalanceClass('balance-decrease')
        setTimeout(() => {
          setBalanceClass('')
        }, 3000);
      }
    }
    setCurrentUser(user.currentUser);
  }, [user, currentUser]);

  return (
    <>
      <Navbar style={{marginTop: '20px'}}>
      <div className="row" style={{width: '100%', margin: '0'}}>
        <span onClick={() => {navigate('/')}}  className="col-7 brand-name-container">
          {/* <Image src={endgameBranch} alt="logo" className="navbar-brand-name"/> */}
          <EndGame/>
        </span>
        <Navbar.Collapse className="col-3" style={{justifyContent: 'end', padding: 0}}>
          {
            user.user ? <div>
              <div className="user-profile">
              <span className={balanceClass} style={{marginRight: '5px'}}>
                {`$${user?.currentUser?.availableBalance ? user?.currentUser?.availableBalance?.toFixed(0) : '0'}`}
              </span>
                <Image
                  src={user?.currentUser?.profilePictureUrl || greyCircle}
                  alt="Profile"
                  className="profile-picture"
                  roundedCircle
                  style={{ width: '50px', height: '50px' , cursor: 'pointer'}}
                  onClick={() => {navigate('/profile', user);}}
                />
              </div>
            </div> : 
            <div>
              <Button
                variant="light"
                onClick={() => navToAuth()}
              >
                Sign In
              </Button>
            </div>
          }
        </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  );
}
