import React, { useState, ChangeEvent } from "react";
import ChatGPTApi from "../../services/ChatGptApi";
import BoxScoreResponse, { TeamStats } from "./BoxScoreResponse/BoxScoreResponse";
import { Player } from "../../types/player.interface";
import Loading from "../../components/Loading/Loading";
import { AuthModal } from "../../components/AuthModal";
import { CurrentUserPlayer } from "../../constants";

interface GptTextComponentProps {
  currentUser: any;
  responseText: any
  currentUserPlayer: CurrentUserPlayer;
  userALineup: any[],
  userBLineup: any[],
}

function GptTextComponent({
  currentUser,
  responseText,
  currentUserPlayer,
  userALineup,
  userBLineup
}: GptTextComponentProps) {
  return (
    <div>
      <div>
        {responseText && (
          <div>
            <BoxScoreResponse data={responseText} currentUser={currentUser} 
            currentUserPlayer={currentUserPlayer} userALineup={userALineup} userBLineup={userBLineup}
            />
          </div>
        ) }
      </div>
    </div>
  );
}

export default GptTextComponent;
