import { Spinner } from 'react-bootstrap';
import styles from './EndgameButton.module.scss';
import classNames from 'classnames';

interface EndGameButtonProps {
  text: string;
  onClick?: () => void;
  position?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

const EndGameButton: React.FC<EndGameButtonProps> = ({text, onClick, position, disabled, style, className}: any) => {
  return (
    <div className={classNames(styles.button, {[styles.buttonFixed]: position !== 'relative'}, className)} onClick={(e) => onClick(e)} style={style}>
      {disabled ?  <Spinner animation="border" size="sm" />  : text}
    </div>
  );
};

export default EndGameButton;