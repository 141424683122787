import { ReactComponent as TwitterSvg } from '../assets/twitter-4.svg';
import { ReactComponent as InstagramSvg } from '../assets/instagram-4.svg';
import TikTokSvg from '../assets/image-3.svg';
import { ReactComponent as DiscordSvg } from '../assets/discord-2.svg';

export const Footer = () => {
  return (
    <>
    <div className="footer">
      <a target='_blank' href="https://discord.gg/RkZPCs5A6c" rel="noreferrer"><DiscordSvg className="social-icon"/></a>
      <a target='_blank' href="https://www.tiktok.com/@playendgameai" rel="noreferrer"><img className="social-icon" src={TikTokSvg} alt="" /></a>
      <a target='_blank' href="https://twitter.com/playEndGameAI" rel="noreferrer"><TwitterSvg className="social-icon"/></a>
      <a target='_blank' href="https://www.instagram.com/playendgameai/" rel="noreferrer"><InstagramSvg className="social-icon"/></a>
    </div>
    </>
  );
};