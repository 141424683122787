import "bootstrap/dist/css/bootstrap.min.css";
import { getAuth } from 'firebase/auth';
import { useEffect, useState } from "react";
import { useAuthState } from 'react-firebase-hooks/auth';
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.scss";
import Auth from "./components/Auth";
import { Match } from "./components/Match/Match";
import OpenMatches from "./components/OpenMatches/OpenMatches";
import Play from "./components/Play/Play";
import { Profile } from "./components/Profile/Profile";
import { postEndGameApi } from "./helpers/endgameApi";
import { TopBar } from "./scenes/global/TopBar";

const auth = getAuth();

function App() {
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [isLoadingCurrentUser, setIsLoadingCurrentUser] = useState<boolean>(false);
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (user && (!currentUser || user.uid !==currentUser?.firebaseUserId) && !isLoggingIn) {
          let newUser;
          setIsLoadingCurrentUser(true);
          try{
            const currentFBUser = auth.currentUser;
            newUser = await postEndGameApi(`/login`, undefined, undefined, currentFBUser?.uid);
            if(newUser) {
              handleChangeUser(newUser)
            }
          } catch(e) {
            console.log('error getting authed user')
          }
          setIsLoadingCurrentUser(false);
          setCurrentUser(newUser);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
      setIsLoadingCurrentUser(false);
    };

    fetchUserData();
  }, [user, currentUser, isLoggingIn]);

  const handleChangeUser = (newUser: any) => {
    setCurrentUser(newUser);
  }

  const onAuthSuccess = (newUser: any) => {
    setCurrentUser(newUser);
    return navigate("/");
  }

  const handleIsLoggingIn = (isLoggingIn: boolean) => setIsLoggingIn(isLoggingIn);

  return (
    <div className="App">
      {location.pathname !== '/login' && <TopBar user={user} currentUser={currentUser}/>}
      <div className="main-content">
      <Routes>
        <Route path="/" element={<Play currentUser={currentUser} handleChangeUser={handleChangeUser} matchData={null} setIsLoggingIn={handleIsLoggingIn} />}/>
        <Route path="/profile" element={<Profile currentUser={currentUser} handleChangeUser={handleChangeUser} firebaseUser={user}/>} />
        <Route path="/play" element={<Play currentUser={currentUser} handleChangeUser={handleChangeUser} matchData={null} setIsLoggingIn={handleIsLoggingIn} />}/>
        <Route path="/login" element={<Auth onSuccess={(newUser: any) => onAuthSuccess(newUser)} modalView={false} setIsLoggingIn={setIsLoggingIn}/>}/>
        <Route path="/match/:matchId" element={<Match currentUser={currentUser} isLoadingCurrentUser={isLoadingCurrentUser} handleChangeUser={handleChangeUser} setIsLoggingIn={handleIsLoggingIn}/>} />
        <Route path="/matches/open" element={<OpenMatches currentUser={{currentUser}}/>} />
      </Routes>
      </div>
    </div>
  );
}

export default App;
