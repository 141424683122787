import React from "react";
import PlayerItem from "../../../components/PlayerItem";
import PositionHeader from "../../../components/PositionHeader";
import { Player } from "../../../types/player.interface";
import styles from './TeamToBeat.module.scss';

interface TeamToBeatListProps {
  teamsToBeat: Player[];
}

const TeamToBeatList: React.FC<TeamToBeatListProps> = ({ teamsToBeat }) => {
  const positionOrder: Record<string, number> = { PG: 1, SG: 2, SF: 3, PF: 4, C: 5 };

  const players = [...teamsToBeat].sort((a: any, b: any) => {
        const positionA = a.position.toUpperCase() as string;
        const positionB = b.position.toUpperCase() as string;
        
        return positionOrder[positionA] - positionOrder[positionB] as number;
  });
  
  return (
    <div className={styles.container}>
      <div className={styles.playerCardContainer}>
        {players.map((player, index) => (
          <div className={styles.positionCol} key={index}>
            <PositionHeader>{player.position}</PositionHeader>
            <PlayerItem player={player} isSelected={true} className={styles.playerItem}/>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamToBeatList;