import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Spinner, Toast } from 'react-bootstrap';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from "react-router-dom";
import ProfilePicture from './ProfilePicture';
import { PaymentModal } from "../PaymentModal";
import RequestWithdrawModal from "../RequestWithdrawalModal/RequestWithdrawModal.component";
import { postEndGameApi, patchEndGameApi } from '../../helpers/endgameApi';
import { Footer } from '../Footer';
import styles from './Profile.module.scss';
import classNames from 'classnames';

interface ProfileProps {
  currentUser: any;
  handleChangeUser: (newUser: any) => void;
  firebaseUser: any
}

export const Profile = (props: ProfileProps) => {
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
  const [showWithdrawalModal, setShowWithdrawalModal] = useState<boolean>(false);
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
  const [toastText, setToastText] = useState<string>('');
  const [matchesCanceling, setMatchesCanceling] = useState<any>({});
  const [winAmount, setWinAmount] = useState<number>(0);
  const [lossAmount, setLossAmount] = useState<number>(0);
  const [isChangingUsername, setIsChangingUsername] = useState<boolean>(false);
  const [newUsername, setNewUsername] = useState<string>('');
  console.log('currentUser', props.currentUser);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    let wins = 0;
    let losses = 0;
    props.currentUser?.matches?.forEach((match: any) => {
      if(match.winnerUserId === props.currentUser?.id) {
        wins = wins + 1;
      } else if(match.winnerUserId) {
        losses = losses +1;
      }
    });
    setWinAmount(wins);
    setLossAmount(losses);
  }, [props.currentUser?.matches, props.currentUser?.id]);

  const handleSignOut = async () => {
    await signOut(auth); 
    await props.handleChangeUser(null);
    navigate('/')
  }

  const depositSuccess = () => {
    setShowPaymentModal(false);
  }

  const withdrawRequestSuccess = (amount: number) => {
    setShowWithdrawalModal(false);
    setToastText('Withdrawal request sent');
    setShowSuccessToast(true);
    props.handleChangeUser({...props.currentUser, availableBalance: props.currentUser.availableBalance - amount})
    setTimeout(() => {
      setShowSuccessToast(false)
      setToastText('');
    }, 3000);
  }

  const cancelMatch = async (matchId: string) => {
    setMatchesCanceling({...matchesCanceling, matchId: matchId});
    try{
      const updatedUser = await postEndGameApi(`/matches/${matchId}/cancel`)
      props.handleChangeUser(updatedUser);
      setToastText('Successfully canceled match');
      setShowSuccessToast(true);
      setTimeout(() => {
        setShowSuccessToast(false)
        setToastText('');
      }, 3000);
    } catch(e) {
      alert('failed to cancel the match')
    }
    setMatchesCanceling({...matchesCanceling, matchId: undefined});
  }

  const changeUsername = async () => {
    try{
      const newUser = await postEndGameApi('/users', undefined, {username: newUsername});
      props.handleChangeUser(({...props.currentUser, username: newUser.username}));
      setIsChangingUsername(false);
    } catch(e) {
      alert('failed to save username');
    }
   }

   const changeEmailPreference = async () => {
    try{
      const newUser = await postEndGameApi('/users', undefined, {subscribedToEmail: !props.currentUser?.subscribedToEmail});
      props.handleChangeUser(({...props.currentUser, subscribedToEmail: newUser.subscribedToEmail}));
    } catch(e) {
      alert('failed to change email preferences');
    }
   }

   const makePrivate = async (matchId: string) => {
    try{
      await patchEndGameApi(`/matches/${matchId}`, undefined, {public: false});
      setToastText('Match now private');
      setShowSuccessToast(true);
      setTimeout(() => {
        setShowSuccessToast(false)
        setToastText('');
      }, 3000);

      const existingMatchIndex = props.currentUser?.matches?.findIndex((x: any) => x.id === matchId);
      const newMatches = props.currentUser?.matches;
      newMatches?.splice(existingMatchIndex, 1, {...props.currentUser?.matches[existingMatchIndex], public: false});
      props.handleChangeUser({...props.currentUser, matches: newMatches});
    } catch(e) {
      console.log('failed to complete change')
    }
   }

   const makePublic = async (matchId: string) => {
    try{
      await patchEndGameApi(`/matches/${matchId}`, undefined, {public: true});
      setToastText('Match now public');
      setShowSuccessToast(true);
      setTimeout(() => {
        setShowSuccessToast(false)
        setToastText('');
      }, 3000);

      const existingMatchIndex = props.currentUser?.matches?.findIndex((x: any) => x.id === matchId);
      const newMatches = props.currentUser?.matches;
      newMatches?.splice(existingMatchIndex, 1, {...props.currentUser?.matches[existingMatchIndex], public: true});
      props.handleChangeUser({...props.currentUser, matches: newMatches});
    } catch(e) {
      console.log('failed to complete change')
    }
   }

  return (
    <Container>
      <Toast show={showSuccessToast} onClose={() => setShowSuccessToast(false)} className='success-toast'>
        <Toast.Body>{toastText}</Toast.Body>
      </Toast>
      <PaymentModal show={showPaymentModal} 
        handleClose={() => {setShowPaymentModal(false)}}
        headerContent={'Deposit Funds'}
        depositSuccess={depositSuccess}
        handleChangeUser={props.handleChangeUser}/>
      <RequestWithdrawModal  
          show={showWithdrawalModal} 
          handleClose={() => {setShowWithdrawalModal(false)}} 
          currentUser={props.currentUser}
          handleChangeUser={props.handleChangeUser}
          withdrawRequestSuccess={withdrawRequestSuccess}
        />
      <Row className={styles.row}>
        <Col className={classNames(styles.col, styles.profileCol)}>
          <ProfilePicture currentUser={props?.currentUser} handleChangeUser={props.handleChangeUser}/>
          <p className={styles.username}>{props?.currentUser?.username}</p>
          <Button onClick={() => handleSignOut()} className={styles.button}>
            Sign Out
          </Button>
        </Col>
        <Col className={styles.col}>
          <div className={styles.section}>
            <h3 className={styles.sectionHeader}>Account Balance</h3>
            <span className={styles.accountBalance}>${props.currentUser?.availableBalance?.toFixed(2)}</span>
            <Button className={styles.depositButton} onClick={() => setShowPaymentModal(true)}>Deposit Money</Button>
            <Button variant='link' className={styles.link} onClick={() => setShowWithdrawalModal(true)}>Withdraw Money</Button>
          </div>
          <div>
            <div className={styles.section}>
              <h3 className={styles.sectionHeader}>Settings</h3>
              <div className={styles.settingsRow}>
                <span><strong>username: </strong>{props.currentUser?.username}</span>  
                <Button variant='link' className={styles.editLink} onClick={() => setIsChangingUsername(true)}>Edit</Button>
              </div>
              {isChangingUsername &&
                <div>
                  <Form.Control placeholder="Enter New Username" className='auth-input'
                    value={newUsername}
                    onChange={(e) => setNewUsername(e.target.value)}
                  />
                  <Button className={styles.button} style={{marginTop: '10px'}} onClick={() => changeUsername()}>Save Username</Button>
                </div>
              }
              <div className={styles.settingsRow}>
                <span><strong>email: </strong>{props.firebaseUser?.email}</span>
              </div>
            </div>
          </div>
        </Col>
        <Col className={styles.col}>
          <div className={styles.section}>
            {props.currentUser?.matches && <h3 className={styles.sectionHeader}>Your Matches</h3>}
            {props.currentUser?.matches && 
              <h5 className={styles.sectionSubHeader}>
                Wins: <span className={styles.red}>{winAmount}</span> / Losses: <span className={styles.red}>{lossAmount}</span>
              </h5>
            }
            {props.currentUser?.matches?.map((match: any) => (
              <div key={match.id} className={styles.match}>
                {match?.winnerUserId && (
                  <>
                    {match.winnerUserId === props.currentUser?.id ? 'You Won' :  'You Lost'} vs. {match.userA?.id === props.currentUser?.id ? match.userB?.username : match.userA?.username}
                    <div>{match?.matchGptOutput?.team1_score} to {match?.matchGptOutput?.team2_score}</div>
                  </>
                )}
                {match?.status === 'open' && 
                  <>
                    <div>Pending</div>
                    <Button className={styles.button} onClick={() => cancelMatch(match.id)} disabled={matchesCanceling[match.id]}>
                      {matchesCanceling[match.id] ? <Spinner animation="border" size="sm" /> : 'Cancel match'}
                    </Button>
                    {match.public ? 
                      <Button className={styles.buttonSecondary} onClick={() =>{makePrivate(match.id)}}>Make Match Private</Button> 
                    : <Button className={styles.buttonSecondary} onClick={() =>{makePublic(match.id)}}>Make Match Public</Button>}
                  </>
                }
                <div>
                  Bet amount: ${match.amount}
                </div>
                <div>
                  <Button className={styles.button} onClick={() => navigate(`/match/${match.id}`)}>View Match</Button>
                </div>
              </div>
            ))}
          </div>       
        </Col>
      </Row>
      <Row style={{marginTop: '20px'}}>
        <div className={styles.section}>
          <h3 className={styles.sectionHeader}>Communication Preferences</h3>
          {props.currentUser?.subscribedToEmail ? 
            <>
              <div>You are currently subscribed to receive daily lineup emails</div>
              <Button variant='link' className={styles.editLink} onClick={() => changeEmailPreference()}>Unsubscribe</Button>
            </>
            :
            <>
              <div>You are currently unsubscribed to receive daily lineup emails</div>
              <Button variant='link' className={styles.editLink} onClick={() => changeEmailPreference()}>Subscribe</Button>
            </>
          }
        </div>
      </Row>
      <Footer/>
    </Container>
  );
};
