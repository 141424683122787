import React from "react";
import { Col, Row, Table, Image } from "react-bootstrap";
import "../styles.css";
import { CurrentUserPlayer } from "../../../constants";
import goldCoin from '../../../assets/gold-coin.png';
import styles from './BoxScoreResponse.module.scss';
import classNames from "classnames";

// Example TeamStats type
type PlayerStats = {
  player_name: string;
  "3PTM": number;
  "3PTA": number;
  FGM: number;
  FGA: number;
  FTM: number;
  FTA: number;
  points: number;
  rebounds: number;
  assists: number;
  steals: number;
  blocks: number;
  turnovers: number;
  fouls: number;
  price: number;
  position: string;
};

export type TeamStats = {
  team1: string;
  team2: string;
  team1_score: number;
  team2_score: number;
  description_of_game: string;
  highlights_of_the_game: string[];
  notable_plays_of_the_game: string[];
  players_team1: PlayerStats[];
  players_team2: PlayerStats[];
};

interface BoxScoreProps {
  data: any;
  currentUser: any;
  currentUserPlayer: any;
  userALineup: any[],
  userBLineup: any[],
}

const BoxScoreResponse: React.FC<BoxScoreProps> = ({ data, currentUser, currentUserPlayer, userALineup, userBLineup}) => {

  const sortByPosition = (players: any): any[] => {
    const positionOrder: Record<string, number> = { PG: 1, SG: 2, SF: 3, PF: 4, C: 5 };

    players.sort((a: any, b: any) => {
        const positionA = a.position.toUpperCase() as string;
        const positionB = b.position.toUpperCase() as string;
        
        return positionOrder[positionA] - positionOrder[positionB] as number;
    });

    return players;
  }
  return (
    <div className="box-score">
      <div className="result-text">
        {currentUser && data?.matchGptOutput?.highlights_of_the_game &&
            ((data?.matchGptOutput?.team2_score && data?.matchGptOutput?.team1_score) && currentUserPlayer === CurrentUserPlayer.A ? 
            (data?.matchGptOutput?.team1_score > data?.matchGptOutput?.team2_score ? <h1 className={classNames(styles.header, styles.green)}>You Won! 🎉</h1> : <h1 className={classNames(styles.header)}>You lost! 😢</h1>)
             : 
             data?.matchGptOutput?.team1_score > data?.matchGptOutput?.team2_score ? <h1 className={classNames(styles.header)}>You Lost! 😢</h1> : <h1 className={classNames(styles.header, styles.green)}>You Won! 🎉</h1>)
          }
        </div>
        <Row style={{marginLeft: '0'}}>
          <Col xs={6}>
            { data?.matchGptOutput?.highlights_of_the_game &&
              <Row>
                <Col style={{height: '75px', paddingLeft: '0px'}}>
                  <h1 className={styles.header}>{data?.matchGptOutput?.team1_score}</h1>
                  {/* {(data?.matchGptOutput?.team1_score > data?.matchGptOutput?.team2_score || (data?.winnerUserId && data?.winnerUserId === data?.userA?.id)) && <span style={{fontSize: '46px'}}>💰</span>} */}
                </Col>
              </Row>
            }
            <Row className={styles.username}>
              {data?.userA?.username}
            </Row>
          </Col>
          <Col xs={6}>
            { data?.matchGptOutput?.highlights_of_the_game &&
              <Row>
                <Col style={{height: '75px', paddingLeft: '0px'}}>
                  <h1 className={styles.header}>{data?.matchGptOutput?.team2_score}</h1>
                  {/* {(data?.matchGptOutput?.team2_score > data?.matchGptOutput?.team1_score || (data?.winnerUserId && data?.winnerUserId === data?.userB?.id)) && <span style={{fontSize: '46px'}}>💰</span>} */}
                </Col>
              </Row>
            }
            <Row className={styles.username}>
              {data?.userB?.username}
            </Row>
          </Col>
        </Row>
        <Row className={styles.contentSection}>
          <Col>
            {userALineup && sortByPosition(userALineup).map((team: any) => (
              <Row>
                <Col xs={2}>
                  <p className={styles.position}>{team.position}</p>
                </Col>
                <Col xs={10} style={{display: 'flex'}}>
                <span className="lineup-position-info">{team.name} (
                  <Image
                    src={goldCoin}
                    alt="goldCoin"
                    style={{ width: '10px', height: '10px', marginBottom: '5px', cursor: 'pointer'}}
                  />
                  {team.price})</span>
                </Col>
              </Row>
            ))}
          </Col>
          <Col>
            {userBLineup && sortByPosition(userBLineup)?.sort().map((team: any) => (
            <Row>
              <Col xs={2}>
                <p className={styles.position}>{team.position}</p>
              </Col>
              <Col xs={10} style={{display: 'flex'}}>
                <span className="lineup-position-info">{team.name} (
                  <Image
                    src={goldCoin}
                    alt="goldCoin"
                    style={{ width: '10px', height: '10px', marginBottom: '5px', cursor: 'pointer'}}
                  />
                  {team.price})</span>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
        {data?.matchGptOutput?.final_60_seconds && <Row className={styles.contentSection}>
          <div className={styles.contentHeader}>Final Minute Play By Play</div>
          <div>
            {data?.matchGptOutput?.final_60_seconds?.map((play: any, index: number) => (
              <div key={index} style={{marginBottom: '8px'}}>🕒 <strong>00:{play.secondsRemaining} - </strong> {play.description}. Score: {play.currentScore}<br/></div>
            ))}
          </div>
        </Row>}
        {data?.matchGptOutput?.description_of_game && <Row className={styles.contentSection}>
          <div className={styles.contentHeader}>
            Summary
          </div>
          <div>
            {data?.matchGptOutput?.description_of_game}
          </div>
        </Row>}
        {data?.matchGptOutput?.highlights_of_the_game && <Row className={styles.contentSection}>
          <div className={styles.contentHeader}>
            Highlights
          </div>
          <div>
            {data?.matchGptOutput?.highlights_of_the_game?.map((highlight: any, index: number) => (
                  <div key={index} style={{marginBottom: '8px'}}>💥 {highlight}<br/></div>
            ))}
          </div>
        </Row>}
        {data?.matchGptOutput?.notable_plays_of_the_game && <Row className={styles.contentSection}>
        <div className={styles.contentHeader}>Notable Plays of the Game.</div>
          <div>
            {data?.matchGptOutput?.notable_plays_of_the_game?.map((play: any, index: number) => (
              <div key={index} style={{marginBottom: '8px'}}>💥 {play}<br/></div>
            ))}
          </div>
        </Row>}
      {data?.matchGptOutput?.players_team1 && <Row className="team">
      <div className={styles.contentHeader}>Box Score</div>
        <h4>{data?.matchGptOutput?.team1}</h4>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Player</th>
              <th>3PTM</th>
              <th>3PTA</th>
              <th>FGM</th>
              <th>FGA</th>
              <th>FTM</th>
              <th>FTA</th>
              <th>Points</th>
              <th>Rebounds</th>
              <th>Assists</th>
              <th>Steals</th>
              <th>Blocks</th>
              <th>Turnovers</th>
              <th>Fouls</th>
            </tr>
          </thead>
          <tbody>
            {data?.matchGptOutput?.players_team1?.map((player: any, index: number) => (
              <tr key={index}>
                <td>{player.player_name}</td>
                <td>{player["3PTM"]}</td>
                <td>{player["3PTA"]}</td>
                <td>{player.FGM}</td>
                <td>{player.FGA}</td>
                <td>{player.FTM}</td>
                <td>{player.FTA}</td>
                <td>{player.points}</td>
                <td>{player.rebounds}</td>
                <td>{player.assists}</td>
                <td>{player.steals}</td>
                <td>{player.blocks}</td>
                <td>{player.turnovers}</td>
                <td>{player.fouls}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>}
      {data?.matchGptOutput?.players_team2 && <Row style={{marginBottom: '40px'}}>
        <h4>{data?.matchGptOutput?.team2}</h4>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Player</th>
              <th>3PTM</th>
              <th>3PTA</th>
              <th>FGM</th>
              <th>FGA</th>
              <th>FTM</th>
              <th>FTA</th>
              <th>Points</th>
              <th>Rebounds</th>
              <th>Assists</th>
              <th>Steals</th>
              <th>Blocks</th>
              <th>Turnovers</th>
              <th>Fouls</th>
            </tr>
          </thead>
          <tbody>
            {data?.matchGptOutput?.players_team2?.map((player: any, index: number) => (
              <tr key={index}>
                <td>{player.player_name}</td>
                <td>{player["3PTM"]}</td>
                <td>{player["3PTA"]}</td>
                <td>{player.FGM}</td>
                <td>{player.FGA}</td>
                <td>{player.FTM}</td>
                <td>{player.FTA}</td>
                <td>{player.points}</td>
                <td>{player.rebounds}</td>
                <td>{player.assists}</td>
                <td>{player.steals}</td>
                <td>{player.blocks}</td>
                <td>{player.turnovers}</td>
                <td>{player.fouls}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>}
    </div>
  );
};

export default BoxScoreResponse;
