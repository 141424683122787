
import { useState } from 'react';
import { Modal, Badge } from 'react-bootstrap';
import {Elements} from '@stripe/react-stripe-js';
import {StripeElementsOptions, loadStripe} from '@stripe/stripe-js';
import { Payment } from './Payment';
import { ReactComponent as EndGame } from '../assets/EndGame.svg';
// import { useStripe } from '@stripe/react-stripe-js';


interface PaymentModalProps {
  show: boolean;
  headerContent: string;
  handleClose: () => void;
  handleChangeUser: (newUser: any) => void;
  depositSuccess: () => void
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);

export const PaymentModal = (props: PaymentModalProps) => {
  const [message, setMessage] = useState("");
  const [depositAmount, setDepositAmount] = useState(2000);
  const [errorMessage, setErrorMessage] = useState('');
  // const stripe = useStripe();

  const options = {
    mode: 'payment',
    amount: depositAmount,
    currency: 'usd',
    // Customizable with appearance API.
    appearance: {/*...*/},
    wallets: {
      applePay: 'always', // Show Apple Pay button always
      googlePay: 'always', // Show Google Pay button always
    }
  };

  // useEffect(() => {
  //   const initializeStripe = async () => {
  //     const appearance = {
  //       theme: 'stripe',
  //       variables: {
  //         borderRadius: '36px',
  //       }
  //     };

  //     const expressCheckoutOptions = {
  //       buttonHeight: '50',
  //       buttonTheme: {
  //         applePay: 'white-outline'
  //       }
  //     };

  //     if (stripe) {
  //       const elements = stripe.elements({
  //         mode: 'payment',
  //         amount: depositAmount,
  //         currency: 'usd',
  //       });

  //       const expressCheckoutElement = elements.create(
  //         'expressCheckout',
  //         expressCheckoutOptions
  //       );

  //       expressCheckoutElement.mount('#express-checkout-element');
  //     }
  //   };

  //   initializeStripe();
  // }, [stripe]);
  
  const Message = ({ message }: {message: any}) => (
    <section>
      <p>{message}</p>
    </section>
  );

  return (
    <>
    {/* <div id="express-checkout-element"> */}
    <Elements stripe={stripePromise} options={options as StripeElementsOptions}>
      <Modal show={props.show} onHide={props.handleClose } centered>
        <Modal.Header closeButton style={{borderBottom: 0}}>
          <h4><strong>{props.headerContent}</strong></h4>
        </Modal.Header>
        <Modal.Body>
        <div className='auth-header-no-margin'>
          <EndGame/>
        </div>
        <h1 style={{textAlign: 'center', margin: '30px'}}>${depositAmount / 100}</h1>
        <div className='d-flex' style={{justifyContent: 'center'}}>
          <Badge className='deposit-option' onClick={() => {setDepositAmount(500)}} bg={depositAmount === 500 ? 'dark' : 'bg-light text-dark'}>5</Badge>
          <Badge className='deposit-option' onClick={() => {setDepositAmount(1000)}} bg={depositAmount === 1000 ? 'dark' : 'bg-light text-dark'}>10</Badge>
          <Badge className='deposit-option' onClick={() => {setDepositAmount(2000)}} bg={depositAmount === 2000 ? 'dark' : 'bg-light text-dark'}>20</Badge>
          <Badge className='deposit-option' onClick={() => {setDepositAmount(3000)}} bg={depositAmount === 3000 ? 'dark' : 'bg-light text-dark'}>30</Badge>
          <Badge className='deposit-option' onClick={() => {setDepositAmount(5000)}} bg={depositAmount === 5000 ? 'dark' : 'bg-light text-dark'}>50</Badge>
        </div>
        { message ? (
          <Message message={message} />
        ) : (
          <section>
            <Payment amount={depositAmount} handleChangeUser={props?.handleChangeUser} depositSuccess={props.depositSuccess}/>
        </section>
        )}
        </Modal.Body>
      </Modal>
      {/* </div> */}
      </Elements>
    </>
  );
};
