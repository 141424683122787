import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDyWtV8VpfxfV097QpPjqbnugyObO4OeoM",
  authDomain: "endgame-f8cfb.firebaseapp.com",
  projectId: "endgame-f8cfb",
  storageBucket: "endgame-f8cfb.appspot.com",
  measurementId: "G-1K8WCZR7EJ",
  messagingSenderId: "152584211344",
  appId: "1:152584211344:web:e0c084d0b531977bd44115",
}

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);

export {app, auth, db, analytics};
