import axios from "axios"
import { getAuth } from 'firebase/auth';


export const patchEndGameApi = async (url: string, queryParam?: string, body?: any, path?: string) => {
  return endGameApi('patch', url, queryParam, body, path);
}

export const postEndGameApi = async (url: string, queryParam?: string, body?: any, path?: string) => {
  return endGameApi('post', url, queryParam, body, path);
}

export const endGameApi = async (method: string, url: string, queryParam?: string, body?: any, path?: string) => {
  try{
    const token = await getAuth().currentUser?.getIdToken(true);

    const finalUrl = `${process.env.REACT_APP_ENDGAME_URL}${url}${path ? '/'+path : ''}${queryParam ? '?'+queryParam: ''}`

    // const response = await axios.post(finalUrl,
    // body, 
    // {headers: {Authorization: `Bearer ${token}`}});

    const response = await axios({
      method,
      url: finalUrl,
      data: body,
      headers: {Authorization: `Bearer ${token}`},
    });

    return response.data;
  } catch(e) {
    console.log(`error calling api: ${url}`);
    throw e;
  }
}