
import { useState } from 'react';
import { postEndGameApi } from '../helpers/endgameApi';
import {useStripe, useElements, ExpressCheckoutElement} from '@stripe/react-stripe-js';
import { StripeElements } from '@stripe/stripe-js/types/stripe-js';

interface PaymentProps {
  amount: number;
  handleChangeUser: (newUser: any) => void;
  depositSuccess: () => void;
}

export const Payment = (props: PaymentProps) => {
  const [message, setMessage] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonsReady, setButtonsReady] = useState(false);

  const Message = ({ message }: {message: any}) => (
    <section>
      <p>{message}</p>
    </section>
  );

  const onReady = ({availablePaymentMethods}: any) => {
    if (!availablePaymentMethods) {
      // No buttons will show
    } else {
      // Optional: Animate in the Element
      setButtonsReady(true)
    }
  };

  const onConfirm = async (event: any) => {
    if (!stripe) {
      // Stripe.js hasn't loaded yet.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const {error: submitError} = await (elements as StripeElements).submit();
    if (submitError) {
      setErrorMessage(submitError?.message as string);
      return;
    }

    // Create the PaymentIntent and obtain clientSecret
    const clientSecret = await postEndGameApi('/intents', undefined, {amount: props.amount});

    // Confirm the PaymentIntent using the details collected by the Express Checkout Element
    const confirmPaymentResponse = await stripe.confirmPayment({
      elements: (elements as StripeElements),
      clientSecret,
      confirmParams: {
        return_url: 'https://localhost:3000/play',
      },
      redirect: 'if_required'
    });
    
    if (confirmPaymentResponse?.error) {
      setErrorMessage(confirmPaymentResponse.error?.message as string);
    } else {
      // console.log(JSON.stringify(confirmPaymentResponse))
      const updatedUser = await postEndGameApi('/confirm-payment', 
      undefined, 
      {
        stripeId: confirmPaymentResponse?.paymentIntent.client_secret, 
        outcome: confirmPaymentResponse.paymentIntent.status
      });
      await props?.handleChangeUser(updatedUser);
      await props.depositSuccess();
    }
  };

  return (
    <>
    {/* {errorMessage} */}
    {/* { buttonsReady } */}
      <ExpressCheckoutElement onConfirm={onConfirm} options={{wallets: {
      applePay: 'always', // Show Apple Pay button always
      googlePay: 'always', // Show Google Pay button always
    }}} /*onReady={onReady}*//>
      {/* : <>loading buttons</>
    } */}
    </>
  );
};
