import "bootstrap/dist/css/bootstrap.min.css";
import moment from 'moment';
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { postEndGameApi } from "../../helpers/endgameApi";
import Header from "../Header/Header";
import styles from './OpenMatches.module.scss';

interface OpenMatchesProps {
  currentUser: any;
}

function OpenMatches(props: OpenMatchesProps) {
  const navigate = useNavigate();
  const [openMatches, setOpenMatches] = useState([]);
  const [gettingMatches, setGettingMatches] = useState(false);

  async function getOpenMatches() {
    if(!openMatches.length && !gettingMatches) {
      try{
        setGettingMatches(true)
        const openMatches = await postEndGameApi('/matches/open');
        setOpenMatches(openMatches);
      } catch(e) {
        console.log('error getting matches')
      }
      setGettingMatches(false)
    }
  }

  useEffect(() => {
      getOpenMatches();
  }, []);

  return (
    <div className={styles.container}>
      <Row>
        <Col>
        <Row>
          <Header>Open Matches</Header>
          <h3 className={styles.subHeader}>Choose a team to challenge</h3>
        </Row>
        <Row >
          {/* <Col>
          <div className={styles.colName}>Link</div>
          </Col> */}
          <Col>
            <div className={styles.colName}>User</div>
          </Col>
          <Col>
            <div className={styles.colName}>Bet</div>
          </Col>
          <Col>
            <div className={styles.colName}>Time/Date</div>
          </Col>
        </Row>
        <div className={styles.matches}>
          { openMatches && openMatches?.map((openMatch: any) => (
            <Row className={styles.row} onClick={() => navigate(`/match/${openMatch.id}`)}>
              {/* <Col>
              <Button variant="link" onClick={() => navigate(`/match/${openMatch.id}`)}>View Lineup</Button>
              </Col> */}
              <Col>
                {openMatch.userA?.username}
              </Col>
              <Col>
                ${openMatch.amount}
              </Col>
              <Col>
                {moment(openMatch.createdAt).format("MMM D, YYYY HH:mm")}
              </Col>
            </Row>
          ))
          }
        </div>
        </Col>
      </Row>
    </div>
  );
}

export default OpenMatches;
