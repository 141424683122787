import { Modal } from 'react-bootstrap';
import Auth from './Auth';

interface AuthModalProps {
  show: boolean;
  handleClose: () => void;
  onAuthSuccess: (newUser: any) => void;
  setIsLoggingIn:(isLoggingIn: boolean) => void;

}

export const AuthModal = (props: AuthModalProps) => {

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton style={{borderBottom: 0}}>
        </Modal.Header>
        <Auth 
          onSuccess={(newUser: any) => props.onAuthSuccess(newUser)} 
          modalView={true} 
          setIsLoggingIn={(isLoggingIn: boolean) => props.setIsLoggingIn(isLoggingIn)}
        />
      </Modal>
    </>
  );
};
